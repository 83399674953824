import React, { CSSProperties } from 'react'

import TikTokIconBw from '@/assets/img/icons/sns/TikTok_B&W.svg'
import TikTokIconClr1 from '@/assets/img/icons/sns/TikTok_Clr_1.svg'
import TikTokIconClr2 from '@/assets/img/icons/sns/TikTok_Clr_2.svg'
import TikTokIconRClr from '@/assets/img/icons/sns/TikTok_R_Clr.svg'
import { Colors, SnsColors } from '@/constants/styles/color'

type IconProps = {
  width?: string
  height?: string
  onClick?: (e: any) => void
  type: 'bk' | 'wh' | 'clr1' | 'clr2' | 'r_clr' | 'i_bk' | 'i_wh' | 'i_clr' | 'i_clr2' | 'sq_clr2'
  style?: CSSProperties
}

const _TiktokIcons = ({ width, height, type }: IconProps) => {
  switch (type) {
    case 'bk':
      return (
        <TikTokIconBw
          fill={Colors.BLACK}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'wh':
      return (
        <TikTokIconBw
          fill={Colors.WHITE}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'clr1':
      return (
        <TikTokIconClr1
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'clr2':
      return (
        <TikTokIconClr2
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'r_clr':
      return (
        <TikTokIconRClr
          style={{
            width: width ? width : '32px',
            height: height ? height : '32px',
          }}
        />
      )
    case 'i_wh':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.WHITE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <TikTokIconBw
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
            />
          </div>
        </div>
      )
    case 'i_bk':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.BLACK,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <TikTokIconBw
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
              fill={Colors.WHITE}
            />
          </div>
        </div>
      )
    case 'i_clr':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.WHITE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <TikTokIconClr2
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
            />
          </div>
        </div>
      )
    case 'i_clr2':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: SnsColors.TIKTOK,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <TikTokIconClr1
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
                margin: 'auto',
              }}
            />
          </div>
        </div>
      )
    case 'sq_clr2':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            background: SnsColors.TIKTOK,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <TikTokIconClr1
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
                margin: 'auto',
              }}
            />
          </div>
        </div>
      )
  }
}

const TiktokIcons = (props: IconProps) => {
  return (
    <div onClick={props.onClick} style={props.style}>
      <_TiktokIcons {...props} />
    </div>
  )
}

export default TiktokIcons
