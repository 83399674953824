import React, { CSSProperties } from 'react'

import LINEIconBw from '@/assets/img/icons/sns/LINE_B&W.svg'
import LINEIconClr from '@/assets/img/icons/sns/LINE_Clr.svg'
import LINEIconRClr from '@/assets/img/icons/sns/LINE_R_Clr.svg'
import { Colors, SnsColors } from '@/constants/styles/color'

type IconProps = {
  width?: string
  height?: string
  onClick?: (e: any) => void
  type: 'bk' | 'wh' | 'clr' | 'r_clr' | 'r_bk' | 'i_bk' | 'i_wh' | 'i_clr' | 'i_clr2' | 'sq_clr2'

  style?: CSSProperties
}

const _LINEIcons = ({ width, height, type }: IconProps) => {
  switch (type) {
    case 'bk':
      return (
        <LINEIconBw
          fill={Colors.BLACK}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'wh':
      return (
        <LINEIconBw
          fill={Colors.WHITE}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'clr':
      return (
        <LINEIconClr
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'r_clr':
      return (
        <LINEIconRClr
          style={{
            width: width ? width : '32px',
            height: height ? height : '32px',
          }}
        />
      )
    case 'r_bk':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '50%',
            background: Colors.WHITE,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${Colors.BLACK}`,
          }}
        >
          <LINEIconBw
            style={{
              width: width ? `calc(${width} / 2)` : '20.57px',
              height: height ? `calc(${height} / 2)` : '20.57px',
              margin: 'auto',
            }}
          />
        </div>
      )
    case 'i_bk':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.BLACK,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <LINEIconBw
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
              fill={Colors.WHITE}
            />
          </div>
        </div>
      )
    case 'i_wh':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.WHITE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <LINEIconBw
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
            />
          </div>
        </div>
      )
    case 'i_clr':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.WHITE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <LINEIconClr
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
            />
          </div>
        </div>
      )
    case 'i_clr2':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: SnsColors.LINE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <LINEIconBw
              fill={Colors.WHITE}
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
                margin: 'auto',
              }}
            />
          </div>
        </div>
      )
    case 'sq_clr2':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            background: SnsColors.LINE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <LINEIconBw
              fill={Colors.WHITE}
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
                margin: 'auto',
              }}
            />
          </div>
        </div>
      )
  }
}

const LINEIcons = (props: IconProps) => {
  return (
    <div onClick={props.onClick} style={props.style}>
      <_LINEIcons {...props} />
    </div>
  )
}

export default LINEIcons
