import React, { CSSProperties } from 'react'

import YoutubeIconBw from '@/assets/img/icons/sns/Youtube_B&W.svg'
import YoutubeIconClr from '@/assets/img/icons/sns/Youtube_Clr.svg'
import YoutubeIconRClr from '@/assets/img/icons/sns/Youtube_R_Clr.svg'
import YoutubeIconPly from '@/assets/img/icons/sns/Youtube_Ply.svg'
import { Colors, SnsColors } from '@/constants/styles/color'

type IconProps = {
  width?: string
  height?: string
  onClick?: (e: any) => void
  type: 'bk' | 'wh' | 'clr' | 'r_clr' | 'i_bk' | 'i_wh' | 'i_clr' | 'i_clr2' | 'sq_clr2' | 'ply'
  style?: CSSProperties
}

const _YoutubeIcons = ({ width, height, type }: IconProps) => {
  switch (type) {
    case 'bk':
      return (
        <YoutubeIconBw
          fill={Colors.BLACK}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'wh':
      return (
        <YoutubeIconBw
          fill={Colors.WHITE}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'clr':
      return (
        <YoutubeIconClr
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'r_clr':
      return (
        <YoutubeIconRClr
          style={{
            width: width ? width : '32px',
            height: height ? height : '32px',
          }}
        />
      )
    case 'i_wh':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.WHITE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <YoutubeIconBw
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
            />
          </div>
        </div>
      )
    case 'i_bk':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.BLACK,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <YoutubeIconBw
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
              fill={Colors.WHITE}
            />
          </div>
        </div>
      )
    case 'i_clr':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.WHITE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <YoutubeIconClr
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
            />
          </div>
        </div>
      )
    case 'i_clr2':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: SnsColors.YOUTUBE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <YoutubeIconBw
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
              fill={Colors.WHITE}
            />
          </div>
        </div>
      )
    case 'sq_clr2':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            background: SnsColors.YOUTUBE,
            display: 'flex',
          }}
        >
          <div
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
              margin: 'auto',
            }}
          >
            <YoutubeIconBw
              style={{
                width: width ? `calc(${width} / 2)` : '24px',
                height: height ? `calc(${height} / 2)` : '24px',
              }}
              fill={Colors.WHITE}
            />
          </div>
        </div>
      )
    case 'ply':
      return (
        <YoutubeIconPly
          style={{
            width: width,
            height: height,
          }}
        />
      )
  }
}

const YoutubeIcons = (props: IconProps) => {
  return (
    <div onClick={props.onClick} style={props.style}>
      <_YoutubeIcons {...props} />
    </div>
  )
}

export default YoutubeIcons
