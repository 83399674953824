import React, { CSSProperties } from 'react'

import ClubhouseIconBw from '@/assets/img/icons/sns/Clubhouse_B&W.svg'
import ClubhouseIconClr from '@/assets/img/icons/sns/Clubhouse_Clr.svg'
import ClubhouseIconRClr from '@/assets/img/icons/sns/Clubhouse_R_Clr.svg'
import { Colors } from '@/constants/styles/color'

type IconProps = {
  width?: string
  height?: string
  onClick?: (e: any) => void
  type: 'bk' | 'wh' | 'clr' | 'r_clr'
  style?: CSSProperties
}

const _ClubhouseIcons = ({ width, height, type }: IconProps) => {
  switch (type) {
    case 'bk':
      return (
        <ClubhouseIconBw
          data-testid="clubhouse-icon"
          fill={Colors.BLACK}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'wh':
      return (
        <ClubhouseIconBw
          fill={Colors.WHITE}
          data-testid="clubhouse-icon"
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'clr':
      return (
        <ClubhouseIconClr
          data-testid="clubhouse-icon"
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'r_clr':
      return (
        <ClubhouseIconRClr
          data-testid="clubhouse-icon"
          style={{
            width: width ? width : '32px',
            height: height ? height : '32px',
          }}
        />
      )
  }
}

const ClubhouseIcons = (props: IconProps) => {
  return (
    <div onClick={props.onClick} style={props.style}>
      <_ClubhouseIcons {...props} />
    </div>
  )
}

export default ClubhouseIcons
