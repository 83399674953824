import React, { CSSProperties } from 'react'
import InstagramIconBw from '@/assets/img/icons/sns/Instagram_B&W.svg'
import InstagramIconRClr from '@/assets/img/icons/sns/Instagram_R_Clr.webp'
import InstagramIconClr from '@/assets/img/icons/sns/Instagram_Clr.webp'
import { Colors, SnsColors } from '@/constants/styles/color'

type IconProps = {
  width?: string
  height?: string
  onClick?: (e: any) => void
  type: 'bk' | 'wh' | 'clr' | 'r_clr' | 'i_bk' | 'i_wh' | 'i_clr' | 'i_clr2' | 'sq_clr2'
  style?: CSSProperties
}

const _InstagramIcons = ({ width, height, type }: IconProps) => {
  switch (type) {
    case 'bk':
      return (
        <InstagramIconBw
          fill={Colors.BLACK}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'wh':
      return (
        <InstagramIconBw
          fill={Colors.WHITE}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
        />
      )
    case 'clr':
      return (
        <img
          src={InstagramIconClr.src}
          style={{
            width: width ? width : '24px',
            height: height ? height : '24px',
          }}
          alt=""
        />
      )
    case 'r_clr':
      return (
        <img
          src={InstagramIconRClr.src}
          style={{
            width: width ? width : '32px',
            height: height ? height : '32px',
          }}
          alt=""
        />
      )
    case 'i_wh':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.WHITE,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <InstagramIconBw
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
            }}
          />
        </div>
      )
    case 'i_bk':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.BLACK,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <InstagramIconBw
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
            }}
            fill={Colors.WHITE}
          />
        </div>
      )
    case 'i_clr':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: Colors.WHITE,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={InstagramIconClr.src}
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
            }}
            alt=""
          />
        </div>
      )
    case 'i_clr2':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            borderRadius: '16px',
            background: SnsColors.INSTAGRAM,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <InstagramIconBw
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
            }}
            fill={Colors.WHITE}
          />
        </div>
      )
    case 'sq_clr2':
      return (
        <div
          style={{
            width: width ? width : '48px',
            height: height ? height : '48px',
            background: SnsColors.INSTAGRAM,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <InstagramIconBw
            style={{
              width: width ? `calc(${width} / 2)` : '24px',
              height: height ? `calc(${height} / 2)` : '24px',
            }}
            fill={Colors.WHITE}
          />
        </div>
      )
  }
}

const InstagramIcons = (props: IconProps) => {
  return (
    <div onClick={props.onClick} style={props.style}>
      <_InstagramIcons {...props} />
    </div>
  )
}

export default InstagramIcons
